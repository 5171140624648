import React, { Component } from "react";
import Fade from "react-reveal";
import Card from '@material-ui/core/Card';
import clslogo from './image/clswhite.png';
import vividlogo from './image/vividwhite.png';
import { Divider } from "@material-ui/core";
import { Helmet } from "react-helmet";

class About extends Component {
  render() {
    if (!this.props.data) return null;

    return (
      <section id="about" style={{textAlign:'-webkit-center'}}>
        <Helmet>
          <meta 
            name='description'
            content='Altnetix is a software development and IT service company providing services to
            any company that seeks our services. Our team works diligently alongside your company
            to ensure that your software is up to date and optimized for the best results.'
            />
        </Helmet>
        <Fade duration={1000}>
          <Card className='about-card' style={{width:'70%',backgroundColor:'#bd3c3c', boxShadow:'-webkit-box-shadow: 0 32px 20px rgb(0 0 0 / 26%)'}}>
          <div className="row">
            <div className="nine columns main-col">
              <h2>About Altnetix</h2>

              <p>
                Altnetix is a software development and IT service company providing services to
                any company that seeks our services. Our team works diligently alongside your company
                to ensure that your software is up to date and optimized for the best results. 
              </p>
              <p>
                Altnetix specializes in web development, app development, trouble shooting and 
                an array of other development for a variety of brands and companies.
              </p>
              <Divider style={{backgroundColor:'white'}}/>
              <div className='client-logos'>
                <img className='clientlogo'src={clslogo} alt='clslogo'/>
                <img className='clientlogo'src={vividlogo} alt='vividlogo'/>
              </div>
            </div>
          </div>
          </Card>
        </Fade>
      </section>
    );
  }
}

export default About;
