import React, { Component } from "react";
import Fade from "react-reveal";
import codingvid from './video/codingvid.mp4'
import logo from './image/altnetixlogotransparent.png'
import { FaCloudsmith } from "react-icons/fa";
import { Helmet } from "react-helmet";


class Header extends Component {
  render() {
    if (!this.props.data) return null;


    return (
      <header id="home" style={{height:'auto', display: 'inline-flex'}}>
        <Helmet>
          <meta 
          name='description'
          content='Altnetix, LLC is a software and application development company'
          />
        </Helmet>
     
          <div className='image-background' style={{position:'absolute', width:'100%', height: '100%'}}>
            <img className='altlogo' src= {logo} alt='alt-logo' style={{position: 'relative', top:0, background:'codingvid.mp4'}}></img>
            <div className="row banner">
          <div className="banner-text">
            <Fade bottom>
              <h1 className="responsive-headline" style={{color:'#fe5555', fontFamily:'Azonix', fontSize: '70px'}}>
                ALTNETIX<br/>
                <h1 className='headline-sub' style={{fontFamily:'Azonix', fontSize:'50px'}}>
                  LABS
                </h1>
              </h1>
            </Fade>
            <Fade bottom duration={1200}>
              <h3 style={{fontFamily:'sans-serif'}}>Software Development | I.T. Services</h3>
            </Fade>
            <hr />
            <Fade bottom duration={2000}>
              <ul className="social">
                <a href='#services' className="button btn project-btn" style={{}}>
                  <i className="fa fa-book"></i>Projects
                </a>
                <a href='#about' className="button btn github-btn">
                  <FaCloudsmith style={{paddingRight:'6px'}}/>Learn More
                </a>
              </ul>
            </Fade>
          </div>
        </div>
          </div>
          <video className ='videoTag' autoPlay loop muted  style={{width:'100%', opacity:'18%', positon: 'fixed', zIndex:-1,objectFit:'cover'}}src={codingvid} type='video/mp4'></video>
       
        <nav id="nav-wrap">
          <a className="mobile-btn" href="#nav-wrap" title="Show navigation" style={{backgroundColor: '#fe5555'}}>
            Show navigation
          </a>
          <a className="mobile-btn" href="#home" title="Hide navigation" style={{backgroundColor:'#fe5555'}}>
            Hide navigation
          </a>

          <ul id="nav" className="nav">
            <li>
              <a className="smoothscroll" href="#about">
                About
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#services">
                Services
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#contact">
                Contact
              </a>
            </li>
          </ul>
        </nav>

        

        <p className="scrolldown">
          <a className="smoothscroll" href="#about">
            <i className="icon-down-circle"></i>
          </a>
        </p>
      </header>
    );
  }
}

export default Header;
