import React, { Component } from "react";
import Slide from "react-reveal";
import {BsCodeSlash} from 'react-icons/bs';
import {BsLaptop} from 'react-icons/bs';
import {BsAppIndicator} from 'react-icons/bs';
import {IoHardwareChipOutline} from 'react-icons/io5';
import { Helmet } from "react-helmet";


class Services extends Component {
  getRandomColor() {
    let letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  render() {
    if (!this.props.data) return null;


    const skills = this.props.data.skills.map((skills) => {
      const backgroundColor = this.getRandomColor();
      const className = "bar-expand " + skills.name.toLowerCase();
      const width = skills.level;

      return (
        <li key={skills.name}>
          <span style={{ width, backgroundColor }} className={className}></span>
          <em>{skills.name}</em>
        </li>
      );
    });

    return (
      <section id="services">
        <Helmet>

          <meta 
            name='description'
            content='We provide services such as Enterprise Troubleshooting, IT Consulting, Industrial-Grade Software Development and Mobile App Development'
            
            />
        </Helmet>
        <Slide left duration={1300}>
          <div className='service-heading'>
            <h1 style={{textAlign:'center', paddingTop:'20px', fontFamily:'Azonix', fontSize:'40px'}}>
              What We Have <br/><br/>To Offer
            </h1>
          </div>
          <div className="row education">
            <div className="three columns header-col">
              <h1>
                <span>Software</span>
              </h1>
            </div>

            <div className="nine columns main-col">
              <div className="row item">
                <div className="twelve columns">
                  <div className='service-item'>
                    <div className='item-text' style={{color:'black', fontWeight:'bold'}}>
                      Industrial-Grade Software Development
                    </div>
                    <div className='service-image'>
                      <BsCodeSlash style={{height:'100px', width:'100px', color:'#fe5555'}} />
                    </div>
                  </div><br></br>
                  <div className='service-message'>
                      Create complex enterprise software, ensure reliable software integration, modernise your legacy system.
                  </div>
                
                  <div className='service-item' style={{color:'black', fontWeight:'bold'}}>
                    <div className='item-text'>
                      Mobile App Development
                    </div>
                    <div className='service-image'>
                      <BsAppIndicator style={{height:'100px', width:'100px', color: '#fe5555'}} />
                    </div>
                  </div><br></br>
                  <div className='service-message'>
                    Create an impactful mobile app that fits your brand and industry within a shorter time frame.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Slide>

        <Slide left duration={1300}>
          <div className="row work">
            <div className="three columns header-col">
              <h1>
                <span>IT Support</span>
              </h1>
            </div>

            <div className="nine columns main-col">
              <div className='service-item' style={{color:'black', fontWeight:'bold'}}>
                <div className='item-text'>
                  IT Consulting
                </div>
                <div className='service-image'>
                  <BsLaptop style={{height:'100px', width:'100px', color: '#fe5555'}} />
                </div>
              </div><br></br>
              <div className='service-message'>
                Trust our top minds to eliminate workflow pain points, implement new tech, and consolidate app portfolios.
              </div>

              <div className='service-item' style={{color:'black', fontWeight:'bold'}}>
                <div className='item-text'>
                  Enterprise Troubleshooting
                </div>
                <div className='service-image'>
                  <IoHardwareChipOutline style={{height:'100px', width:'100px', color: '#fe5555'}} />
                </div>
              </div><br></br>
              <div className='service-message'>
                Our team is capable of finding a quick and sustainable solution for any technological issue 
                or trouble you may have.
              </div>

            </div>
          </div>
        </Slide>

        <Slide left duration={1300}>
          <div className="row skill">
            <div className="three columns header-col">
              <h1>
                <span>Skills</span>
              </h1>
            </div>

            <div className="nine columns main-col">
              <p>Below are the various programming languages and frameworks that we are familiar with</p>

              <div className="bars">
                <ul className="skills">{skills}</ul>
              </div>
            </div>
          </div>
        </Slide>
      </section>
    );
  }
}

export default Services;
